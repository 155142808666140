import React, { useState, useEffect } from "react";
import { Image } from "semantic-ui-react";
import { isEmpty, get } from "lodash";

//Components
import Text from "../Text";

//Constants
import { phoneFormat } from "../../constants/Utils";

//Assets
import Miami from "../../assets/cities/Miami.png";
import MiamiGardens from "../../assets/cities/MiamiGardens.png";
import Surfside from "../../assets/cities/Surfside.png";
import WestPark from "../../assets/cities/WestPark.png";
import NorthMiami from "../../assets/cities/NorthMiami.png";
import NorthMiamiBeach from "../../assets/cities/NorthMiamiBeach.png";
import Apopka from "../../assets/cities/Apopka.png";
import Lauderhill from "../../assets/cities/Lauderhill.png";
import Miramar from "../../assets/cities/Miramar.png";
import MiamiDadeCounty from "../../assets/cities/MiamiDadeCounty.png";
import HialeahGardens from "../../assets/cities/HialeahGardens.png";
import Melbourne from "../../assets/cities/Melbourne.png";
import CoralSprings from "../../assets/cities/CoralSprings.png";
import OpaLocka from "../../assets/cities/OpaLocka.png";
import Oviedo from "../../assets/cities/Oviedo.png";

//Styles
import styles from "./styles.module.css";

const CityHeader = ({ cityObj }) => {
	const [seal, setSeal] = useState(null);
	const [countySeal, setCountySeal] = useState(null);

	useEffect(() => {
		let seal = null;
		let countySeal = null;
		if (!isEmpty(cityObj)) {
			switch (cityObj.url) {
				case "Miami":
					seal = Miami;
					countySeal = MiamiDadeCounty;
					break;
				case "Miami Gardens":
					seal = MiamiGardens;
					break;
				case "Surfside":
					seal = Surfside;
					break;
				case "West Park":
					seal = WestPark;
					break;
				case "North Miami":
					seal = NorthMiami;
					break;
				case "North Miami Beach":
					seal = NorthMiamiBeach;
					break;
				case "Apopka":
					seal = Apopka;
					break;
				case "Lauderhill":
					seal = Lauderhill;
					break;
				case "Miramar":
					seal = Miramar;
					break;
				case "HialeahGardens":
					seal = HialeahGardens;
					break;
				case "Melbourne":
					seal = Melbourne;
					break;
				case "CoralSprings":
					seal = CoralSprings;
					break;
				case "OpaLocka":
					seal = OpaLocka;
					break;
				case "Oviedo":
					seal = Oviedo;
					break;

				default:
					break;
			}
		}
		setSeal(seal);
		setCountySeal(countySeal);
	}, [cityObj]);

	return (
		<div className={styles.titleRow}>
			<div className={styles.leftSide}>
				<Image className={styles.logo} src={seal} />
			</div>
			<div className={styles.titleContainer}>
				<Text className={styles.titleText}>{`City Of ${get(cityObj, "name", "")}`}</Text>
				<Text className={styles.titleText}>{get(cityObj, "address", "")}</Text>
				<Text className={styles.titleText}>{phoneFormat(get(cityObj, "phone", ""))}</Text>
			</div>
			<div className={styles.rightSide}>{get(cityObj, "url", "") === "Miami" && <Image className={styles.logo2} src={countySeal} />}</div>
		</div>
	);
};

export default CityHeader;
